<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_notification") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <div class="grid grid-cols-12">
        <div
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t("labels.type")
            }}</label>
            <form-select
              class="w-full sm:w-1/2"
              id="type-select"
              :invalid="false"
              :optionLabel="['value']"
              :options="notificationTypes"
              translatableLabel="notification_types"
              v-model="notificationType"
            />
          </div>
          <hr class="my-2 w-full border-t border-gray-300" />
        </div>
      </div>
      <div v-if="notificationType === 'alteration'" class="grid grid-cols-12">
        <div
          v-for="alterationType of alterationTypes"
          :key="alterationType.label"
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t(`alteration_types.${alterationType.label}`)
            }}</label>
            <div class="flex w-full flex-col gap-4 sm:w-1/2">
              <div class="flex gap-3">
                <form-checkbox
                  :falseValue="false"
                  :id="`${alterationType.label}-checkbox`"
                  :invalid="false"
                  :trueValue="true"
                  :variant="company.primary"
                  v-model="alterationType.includes"
                />
                <label :for="`${alterationType.labe}-checkbox`">{{
                  t(`labels.includes_${alterationType.label}`)
                }}</label>
              </div>
              <form-textarea
                v-if="alterationType.includes"
                class="w-full"
                :id="`${alterationType.label}-textarea`"
                :invalid="
                  alterationType.includes && !alterationType.contractorInfo
                    ? true
                    : false
                "
                :placeholder="t('form.placeholders.contractor')"
                :rows="5"
                v-model="alterationType.contractorInfo"
              />
              <form-textarea
                v-if="alterationType.includes && alterationType.supervisor"
                class="w-full"
                :id="`${alterationType.label}-supervisor-textarea`"
                :invalid="false"
                :placeholder="t('form.placeholders.supervisor')"
                :rows="5"
                v-model="alterationType.supervisorInfo"
              />
            </div>
          </div>
          <hr class="my-2 w-full border-t border-gray-300" />
        </div>
      </div>
      <div v-if="notificationType === 'meter'" class="grid grid-cols-12">
        <div
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t("labels.meter_type")
            }}</label>
            <form-select
              class="w-full sm:w-1/2"
              id="type-select"
              :invalid="false"
              :optionLabel="['value']"
              :options="meterTypes"
              translatableLabel="meter_types"
              v-model="meterType"
            />
          </div>
          <hr class="my-2 w-full border-t border-gray-300" />
        </div>
      </div>
      <div v-if="notificationType === 'request'" class="grid grid-cols-12">
        <div
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t("labels.image")
            }}</label>
            <form-filepicker
              class="w-full sm:w-1/2"
              accept="image/jpeg, image/png"
              :disabled="waiting"
              id="notification-image-upload"
              label="choose_file"
              :multiple="false"
              :showFilename="true"
              :textVariant="company.primaryText"
              :variant="company.primary"
              v-model="image"
            />
          </div>
          <hr class="my-2 w-full border-t border-gray-300" />
        </div>
      </div>
      <div v-if="notificationType === 'move'" class="grid grid-cols-12">
        <div
          class="col-span-12 sm:px-2 md:col-span-10 lg:col-span-9 xl:col-span-7"
        >
          <div class="my-3 justify-between sm:flex">
            <label class="mb-1 block w-full sm:w-1/2">{{
              t("labels.movers")
            }}</label>
            <div class="flex w-full flex-col gap-2 sm:w-1/2">
              <div v-for="(mover, index) of movers" :key="index">
                <label>{{ t("labels.mover", { n: index + 1 }) }}</label>
                <div class="flex gap-2 pt-1">
                  <form-input
                    class="w-full"
                    :invalid="!mover.value.length"
                    v-model="mover.value"
                  />
                  <form-button
                    v-if="movers?.length > 1"
                    :command="() => deleteMover(index)"
                    :icon="TrashIcon"
                    id="mover-delete-button"
                    :label="null"
                    textVariant="light"
                    type="button"
                    variant="danger"
                  />
                </div>
              </div>
              <form-button
                :command="() => addMover()"
                :icon="PlusIcon"
                id="mover-add-button"
                label="add"
                textVariant="light"
                type="button"
                :variant="company.primary"
              />
            </div>
          </div>
          <hr class="my-2 w-full border-t border-gray-300" />
        </div>
      </div>
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        :key="formKey"
        @stateChange="watchFields"
        @submitForm="createNotification"
      />
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import FormButton from "../components/form/FormButton.vue";
import FormCheckbox from "../components/form/FormCheckbox.vue";
import FormFilepicker from "../components/form/FormFilepicker.vue";
import FormInput from "../components/form/FormInput.vue";
import FormSelect from "../components/form/FormSelect.vue";
import FormTextarea from "../components/form/FormTextarea.vue";
import { getISODate } from "../utils/dateUtils";

export default {
  components: {
    Breadcrumb,
    CustomForm,
    FormButton,
    FormCheckbox,
    FormInput,
    FormFilepicker,
    FormSelect,
    FormTextarea,
    PlusIcon,
    TrashIcon,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      return false;
    });

    const notificationType = ref(null);
    const notificationTypes = [
      { value: "alteration" },
      { value: "meter" },
      { value: "move" },
      { value: "request" },
    ];

    const meterType = ref(null);
    const meterTypes = [
      { value: "cold" },
      { value: "district" },
      { value: "electricity" },
      { value: "hot" },
    ];

    const alterationTypes = reactive([
      {
        contractorInfo: "",
        includes: false,
        label: "construction",
        supervisor: true,
        supervisorInfo: "",
      },
      {
        contractorInfo: "",
        includes: false,
        label: "electricity",
      },
      {
        contractorInfo: "",
        includes: false,
        label: "hvac",
      },
      {
        contractorInfo: "",
        includes: false,
        label: "water",
      },
    ]);

    const image = ref(null);
    const movers = ref([{ value: "" }]);

    const formKey = ref(0);
    const formElements = ref(null);

    const setFormElements = (state = null) => {
      if (notificationType.value !== "move") {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "topic",
            label: "topic",
            rules: ["isRequired"],
            type: "text",
            value: state?.topic.value || "",
            variable: "topic",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "textarea",
            id: "content",
            label: "content",
            rows: 5,
            rules: ["isRequired"],
            value: state?.content.value || "",
            variable: "content",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "estate",
            label: "estate_name",
            optionLabel: ["name"],
            options: estates.value,
            rules: ["isRequired"],
            value: state?.estate.value || "",
            variable: "estate",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "building",
            label: "building",
            optionLabel: ["name", "address", "postalCode", "city"],
            options:
              estates.value.find((estate) => estate.id == state?.estate.value)
                ?.buildings || [],
            rules: ["isRequired"],
            value: state?.building.value,
            variable: "building",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "apartment",
            label: "apartment",
            rules: ["isRequired"],
            type: "text",
            value: state?.apartment.value,
            variable: "apartment",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "send",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      } else {
        formElements.value = [
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "estate",
            label: "estate_name",
            optionLabel: ["name"],
            options: estates.value,
            rules: ["isRequired"],
            value: state?.estate.value || "",
            variable: "estate",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "building",
            label: "building",
            optionLabel: ["name", "address", "postalCode", "city"],
            options:
              estates.value.find((estate) => estate.id == state?.estate.value)
                ?.buildings || [],
            rules: ["isRequired"],
            value: state?.building.value,
            variable: "building",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "apartment",
            label: "apartment",
            rules: ["isRequired"],
            type: "text",
            value: state?.apartment.value,
            variable: "apartment",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "datepicker",
            id: "move-date",
            label: "move_date",
            rules: ["isRequired"],
            value: getISODate(new Date()),
            variable: "moveDate",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "move-type",
            label: "move_type",
            optionLabel: ["name"],
            options: [
              { name: t("move_types.in"), value: "in" },
              { name: t("move_types.out"), value: "out" },
            ],
            rules: ["isRequired"],
            value: undefined,
            variable: "moveType",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "checkbox",
            id: "inhabited",
            label: "inhabited",
            invalid: false,
            rules: [],
            value: false,
            variable: "inhabited",
            variant: company.value.primary,
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "checkbox",
            id: "empty",
            label: "is_empty",
            invalid: false,
            rules: [],
            value: false,
            variable: "empty",
            variant: company.value.primary,
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "select",
            id: "role",
            label: "declarant",
            optionLabel: ["name"],
            options: [
              { name: t("roles.owner"), value: "owner" },
              { name: t("roles.tenant"), value: "tenant" },
            ],
            rules: ["isRequired"],
            value: undefined,
            variable: "declarant",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "checkbox",
            id: "declarant-is-moving",
            label: "is_moving",
            invalid: false,
            rules: [],
            value: false,
            variable: "isMoving",
            variant: company.value.primary,
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "address-new",
            label: "address_new",
            rules: [],
            type: "text",
            value: "",
            variable: "addressNew",
          },
          {
            class:
              "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
            element: "input",
            id: "address-old",
            label: "address_old",
            rules: [],
            type: "text",
            value: "",
            variable: "addressOld",
          },
          {
            class: "col-span-12 text-right",
            element: "button",
            id: "submit-button",
            label: "send",
            textVariant: company.value.primaryText,
            type: "submit",
            variant: company.value.primary,
          },
        ];
      }
    };

    const addMover = () => {
      movers.value.push({ value: "" });
    };

    const deleteMover = (index) => {
      movers.value.splice(index, 1);
    };

    const createNotification = (formData) => {
      const data = {
        apartment: formData.apartment,
        buildingId: formData.building,
        companyId: company.value.id,
        content: formData.content,
        estateId: formData.estate,
        meterType: meterType.value,
        topic: formData.topic,
        type: notificationType.value,
        user: user.value.id,
      };
      if (notificationType.value === "alteration") {
        data.alterationTypes = alterationTypes;
      }
      if (notificationType.value === "request" && image.value) {
        data.image = image.value;
      }
      if (notificationType.value === "move") {
        data.movers = movers.value;
        data.moveDate = formData.moveDate;
        data.moveType = formData.moveType;
        data.inhabited = formData.inhabited;
        data.empty = formData.empty;
        data.declarant = formData.declarant;
        data.isMoving = formData.isMoving;
        data.addressNew = formData.addressNew;
        data.addressOld = formData.addressOld;
      }
      store.dispatch("notification/createNotification", data);
    };

    const watchFields = (state) => {
      if (state.estate.value) {
        setFormElements(state);
      }
    };

    onMounted(() => {
      if (company.value.id && estates.value?.length) {
        setFormElements();
      }
      if (!estates.value?.length) {
        store.dispatch("estate/getEstates", company.value.id);
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    watch(
      () => notificationType.value,
      () => {
        formKey.value++;
        setFormElements();
      }
    );

    return {
      addMover,
      alterationTypes,
      company,
      createNotification,
      deleteMover,
      estates,
      formElements,
      formKey,
      image,
      meterType,
      meterTypes,
      movers,
      notificationType,
      notificationTypes,
      PlusIcon,
      t,
      TrashIcon,
      waiting,
      watchFields,
    };
  },
};
</script>
